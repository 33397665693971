<template>
    <div class="flex flex-col">
        <warning
            icon="regular/calendar-clock"
            :title="$t('error.warning')"
            :content="$t('error.selectAccountWarning')"
            v-if="!validAccount"
        />

        <template v-else>
            <portal to="content-header-left">
                <activix-reload :loading="isLoading" @click="fetchEvents" />
            </portal>

            <div class="flex-1 flex" v-if="validDivision">
                <div
                    class="box | h-screen-80 flex flex-col"
                    :class="[readOnly ? 'w-full' : 'w-3/4', { loading: isLoading }]"
                >
                    <div class="box-body | flex-1 flex flex-col">
                        <activix-calendar
                            :button-text-override="buttonTextOverride"
                            :config="calendarConfig"
                            :events="getEvents"
                            ref="calendar"
                            @drop="onDrop"
                            @event-after-render="onEventAfterRender"
                            @event-click="onEventClick"
                            @event-destroy="onEventDestroy"
                            @event-drop="onEventChange"
                            @event-resize="onEventChange"
                            @view-render="onViewRender"
                        />
                    </div>
                </div>

                <div class="box | flex flex-col w-1/4 ml-6 h-screen-80" :class="{ loading: isLoading }" v-if="!readOnly">
                    <div class="box-header | flex items-center justify-between border-b">
                        <h4 class="box-title">
                            {{ $t('schedule.users') }}
                        </h4>
                        <activix-input
                            class="max-w-64 ml-4"
                            input-class="input-sm"
                            :placeholder="$t('search.search')"
                            v-model="searchUser"
                        />
                    </div>
                    <div class="box-body | flex-1 flex flex-col p-0 h-0">
                        <div class="overflow-y-auto px-6 pb-6">
                            <div
                                class="flex items-center justify-center h-8 font-semibold text-blue-600"
                                :class="{ 'cursor-pointer': visibleUsers.length }"
                                @click="resetVisibleUsers"
                            >
                                <template v-if="visibleUsers.length">
                                    {{ $t('schedule.visibility.showAll') }}
                                </template>
                            </div>

                            <schedule-user
                                :class="{ 'mt-2': index !== 0 }"
                                :user="user"
                                :color="getColor(index)"
                                :visible-users="visibleUsers"
                                :key="user.id"
                                @toggle-visibility="toggleUserVisibility(user.id)"
                                v-for="(user, index) in filteredUsers"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </template>

        <activix-confirm-modal
            type="warning"
            :content="$t('schedule.deleteModal', { user: deleteModal.user })"
            :opened.sync="deleteModal.opened"
            @approve="deleteSchedule"
            @closed="resetDeleteModal"
        />
        <select-division type="schedule" :opened.sync="selectDivisionOpened" />
    </div>
</template>

<script>
    import { upperFirst } from 'lodash-es';
    import { mapState } from 'pinia';
    import { random as randomColors } from '../utils/colors.js';
    import { sort } from '../utils/index.js';
    import colors from '../../tailwind.colors.js';
    import Division from '../entities/Division.js';

    import ScheduleUser from '../components/schedule/ScheduleUser.vue';
    import SelectDivision from '../components/modals/SelectDivision.vue';
    import Warning from '../components/Warning.vue';
    import TrackView from '../mixins/TrackView.js';
    import { useContextStore } from '../store/modules/context/store.js';
    import { useGlobalStore } from '../store/store.js';

    const defaultView = 'agendaDay';
    const dayHeader = {
        left: 'prev,next today',
        center: 'title',
        right: 'agendaWeek,agendaDay',
    };

    const weekHeader = {
        left: '',
        center: 'title',
        right: 'agendaWeek,agendaDay',
    };

    const startOfWeek = now().subDays(now().day());
    const startOfNextWeek = startOfWeek.clone().addWeeks(1);

    export default {
        name: 'Schedule',

        components: {
            ScheduleUser,
            SelectDivision,
            Warning,
        },

        mixins: [TrackView],

        data() {
            return {
                searchUser: '',
                events: [],
                calendarConfig: {
                    columnHeaderFormat: 'dddd',
                    defaultView,
                    droppable: true,
                    editable: true,
                    eventConstraint: {
                        start: '00:00',
                        end: '24:00',
                    },
                    firstDay: 0,
                    header: dayHeader,
                    navLinks: true,
                    slotEventOverlap: false,
                    titleFormat: `[ ${this.$t('schedule.calendarTitle')} ]`,
                    validRange: {
                        start: startOfWeek.toDateString(),
                        end: startOfNextWeek.toDateString(),
                    },
                    views: {
                        week: {
                            columnHeaderFormat: 'ddd D',
                        },
                    },
                    drop: this.onDrop,
                },
                currentView: defaultView,
                deleteModal: {
                    opened: false,
                    scheduleId: null,
                    user: '',
                },
                selectDivisionOpened: false,
                showAllUser: true,
                visibleUsers: [],
            };
        },

        computed: {
            ...mapState(useGlobalStore, ['authUser', 'activeUsers']),
            ...mapState(useContextStore, {
                contextAccount: 'account',
                contextDivisionId: 'divisionId',
            }),

            buttonTextOverride() {
                return {
                    agendaWeek: upperFirst(this.$tc('delays.week')),
                };
            },

            readOnly() {
                return this.authUser.hasSimpleAccess();
            },

            isLoading() {
                return this.$wait.is(['fetching.contextAccount', 'fetching.schedule']);
            },

            validAccount() {
                return !!this.contextAccount.id;
            },

            validDivision() {
                return this.$route.meta.header.division.includes(this.contextDivisionId);
            },

            filteredEvents() {
                return this.events.filter(
                    event => !this.visibleUsers.length || this.visibleUsers.includes(event.userId),
                );
            },

            filteredUsers() {
                const users = this.contextAccount.users.filter(user => {
                    return (
                        this.userInSearch(user) &&
                        user.active &&
                        !user.hide_in_user_select &&
                        (this.contextDivisionId == Division.FINANCE ||
                            user.divisions.some(d => d.id == this.contextDivisionId))
                    );
                });

                return sort(users, 'fullName');
            },
        },

        watch: {
            'contextAccount.id'() {
                this.visibleUsers = this.getFiltersCache();
                this.fetchEvents();
            },

            contextDivisionId: {
                immediate: true,
                handler() {
                    if (!this.contextDivisionId) {
                        this.$nextTick(() => {
                            this.selectDivisionOpened = true;
                        });
                    } else {
                        if (this.readOnly) {
                            this.$nextTick(() => {
                                this.$refs.calendar.fireMethod('changeView', 'agendaWeek');
                                this.$refs.calendar.fireMethod('option', 'droppable', false);
                                this.$refs.calendar.fireMethod('option', 'editable', false);
                                this.$refs.calendar.fireMethod('option', 'allDaySlot', false);
                            });
                        }

                        this.searchUser = '';
                        this.visibleUsers = this.getFiltersCache();
                        this.fetchEvents();
                    }
                },
            },

            currentView(newView) {
                if (newView == 'agendaWeek') {
                    this.$refs.calendar.fireMethod('option', 'header', weekHeader);
                } else {
                    this.$refs.calendar.fireMethod('option', 'header', dayHeader);
                }
            },

            events() {
                this.$refs.calendar.fireMethod('refetchEvents');
            },

            visibleUsers() {
                this.$refs.calendar.fireMethod('refetchEvents');

                this.updateFiltersCache();
            },
        },

        methods: {
            resetVisibleUsers() {
                this.visibleUsers = [];
            },

            toggleUserVisibility(userId) {
                const userVisibilityIndex = this.visibleUsers.findIndex(id => id == userId);

                if (userVisibilityIndex === -1) {
                    this.visibleUsers.push(userId);
                } else {
                    this.visibleUsers.splice(userVisibilityIndex, 1);
                }
            },

            getFiltersCache() {
                return this.$ls.get(`filters:schedule:${this.contextAccount.id}:${this.contextDivisionId}`) || [];
            },

            updateFiltersCache() {
                if (!this.contextAccount.id || !this.contextDivisionId) {
                    return;
                }

                if (!this.visibleUsers.length && !this.getFiltersCache().length) {
                    return;
                }

                this.$ls.set(`filters:schedule:${this.contextAccount.id}:${this.contextDivisionId}`, this.visibleUsers);
            },

            userInSearch(user) {
                return empty(this.searchUser) || user.fullName.toLowerCase().includes(this.searchUser.toLowerCase());
            },

            getColor(index) {
                if (index === -1) {
                    return {
                        background: colors.grey[100],
                        border: colors.grey[300],
                        text: colors.red,
                    };
                }

                while (index >= randomColors.length) {
                    index -= randomColors.length;
                }

                return randomColors[index];
            },

            getEvents(start, end, timezone, callback) {
                callback(this.filteredEvents);
            },

            onDrop(date, jsEvent) {
                const element = jsEvent.target;
                const userId = element.dataset.id;
                const user = this.contextAccount.users.find(u => u.id == userId);
                const allDay = !date.hasTime();

                if (!user) {
                    return;
                }

                this.createSchedule({
                    user_id: userId,
                    division_id: this.contextDivisionId,
                    weekday: locale_time(date).isoWeekday(),
                    start_time: allDay ? '00:00:00' : locale_time(date).toString(),
                    end_time: allDay
                        ? '00:00:00'
                        : locale_time(date)
                            .addHours(2)
                            .toString(),
                });
            },

            onEventAfterRender(event, element) {
                this.$tooltip.destroy(element);

                const start = locale_time(event.start).toTimeShortString();
                const end = locale_time(event.end).toTimeShortString();
                const tooltip = `${start} - ${end}<br>${event.tooltip}`;

                this.$nextTick(() => {
                    this.$tooltip.init(element, tooltip);
                });
            },

            onEventDestroy(event, element) {
                this.$tooltip.destroy(element);
            },

            onEventChange(event, delta, revert) {
                this.updateSchedule(event.id, event).catch(() => {
                    revert();
                });
            },

            onEventClick(event) {
                if (!this.readOnly) {
                    this.deleteModal.opened = true;
                    this.deleteModal.scheduleId = event.id;
                    this.deleteModal.user = event.title;
                }
            },

            onViewRender(view) {
                this.currentView = view.name;
            },

            parseEvent(event) {
                if (this.readOnly) {
                    return this.parseEventMySchedule(event);
                }

                return this.parseEventSchedule(event);
            },

            parseEventSchedule(event) {
                const weekDay = event.weekday % 7;
                const eventStart = startOfWeek
                    .clone()
                    .setTime(event.start_time)
                    .day(weekDay);
                const eventEnd = eventStart.clone().setTime(event.end_time);
                const user = this.contextAccount.users.find(u => u.id == event.user_id);
                const userIndex = this.filteredUsers.findIndex(u => u.id == event.user_id);
                const color = this.getColor(userIndex);

                if (eventEnd.isBefore(eventStart)) {
                    eventEnd.addDays().setTime(event.end_time);
                }

                return {
                    id: event.id,
                    userId: user.id,
                    title: user.fullName,
                    tooltip: this.formatTooltip(user),
                    start: eventStart.toString(),
                    end: eventEnd.toString(),
                    backgroundColor: color.background,
                    textColor: color.text,
                    borderColor: color.border,
                    allDay: eventStart.isStartOfDay() && eventEnd.isStartOfDay(),
                };
            },

            parseEventMySchedule(event) {
                const weekDay = event.weekday % 7;
                const eventStart = locale_dt(event.start_time).isoWeekday(weekDay);
                const eventEnd = locale_dt(event.end_time).isoWeekday(weekDay);
                const color = this.getColor(0);

                let title = Division.getTranslation(event.division_id);
                if (!title && event.lead_form) {
                    title = this.$t(`leadForms.${event.lead_form.name}`);
                }

                return {
                    id: event.id,
                    title,
                    tooltip: '',
                    start: eventStart.toString(),
                    end: eventEnd.toString(),
                    backgroundColor: color.background,
                    textColor: color.text,
                    borderColor: color.border,
                };
            },

            formatTooltip(user) {
                let name = user.fullName;

                if (!user.active) {
                    name += `<br>${this.$t('schedule.userInactive')}`;
                }

                if (!user.divisions.some(d => d.id == this.contextDivisionId)) {
                    name += `<br>${this.$t('schedule.userNoAccessDivision')}`;
                }

                return name;
            },

            async createSchedule(payload) {
                try {
                    const response = await this.$axios.post('v1/schedules', payload);
                    const event = this.parseEvent(response.data.data);

                    this.events.push(event);

                    this.$refs.calendar.fireMethod('renderEvent', event);
                } catch (error) {
                    this.$notify.error(this.$t('schedule.alerts.store.error'));

                    throw error;
                }
            },

            async updateSchedule(id, event) {
                try {
                    await this.$axios.put(`v1/schedules/${id}`, {
                        weekday: locale_time(event.start).isoWeekday(),
                        start_time: event.allDay ? '00:00:00' : locale_time(event.start).toString(),
                        end_time: event.allDay ? '00:00:00' : locale_time(event.end).toString(),
                    });

                    const updatedEvent = this.events.find(event => event.id == id);
                    updatedEvent.start = locale_dt(event.start).toString();
                    updatedEvent.end = locale_dt(event.end).toString();
                    updatedEvent.allDay = event.allDay;
                } catch (error) {
                    this.$notify.error(this.$t('schedule.alerts.update.error'));

                    throw error;
                }
            },

            async deleteSchedule() {
                const id = this.deleteModal.scheduleId;

                if (!id) {
                    return;
                }

                try {
                    await this.$axios.delete(`v1/schedules/${id}`);

                    const eventIndex = this.events.findIndex(event => event.id == id);

                    if (eventIndex !== -1) {
                        this.events.splice(eventIndex, 1);
                    }

                    this.$refs.calendar.fireMethod('removeEvents', [id]);
                } catch (error) {
                    this.$notify.error(this.$t('schedule.alerts.destroy.error'));

                    throw error;
                }
            },

            resetDeleteModal() {
                this.deleteModal.scheduleId = null;
                this.deleteModal.user = '';
            },

            fetchEvents() {
                if (!this.validAccount || !this.validDivision) {
                    return;
                }

                this.$wait.start('fetching.schedule');

                this.$axios
                    .get('v1/schedules', {
                        params: {
                            account_id: this.contextAccount.id,
                            division_id: this.contextDivisionId,
                        },
                    })
                    .then(response => {
                        this.$wait.end('fetching.schedule');

                        this.events = response.data.data.map(event => {
                            return this.parseEvent(event);
                        });
                    })
                    .catch(error => {
                        this.$wait.end('fetching.schedule');

                        throw error;
                    });
            },
        },
    };
</script>
