import Enum from './Enum.js';

export default class UserAvailability extends Enum {
    static get entries() {
        return {
            schedule: 'schedule',
            offline_today: 'offline_today',
            offline_forever: 'offline_forever',
        };
    }

    static get SCHEDULE() {
        return this.entries.schedule;
    }

    static get OFFLINE_TODAY() {
        return this.entries.offline_today;
    }

    static get OFFLINE_FOREVER() {
        return this.entries.offline_forever;
    }
}
