<template>
    <!-- STOP REMOVING THE DATA-ID IT BREAKS THE DRAG AND DROP,
    IF YOU REMOVE IT THEN FIND A FIX FOR IT -->
    <div
        class="py-2 px-2 rounded font-bold flex items-center justify-between border"
        :class="{ 'cursor-move': visible, 'disabled opacity-50': !visible }"
        :data-id="user.id"
        :style="style"
    >
        <activix-tooltip :content="disabledTooltip">
            <span>{{ user.fullName }}</span>
        </activix-tooltip>

        <div>
            <activix-tooltip :content="availabilityTooltip" v-if="isOffline">
                <icon name="bold/alert-triangle" class="ml-2 opacity-75" />
            </activix-tooltip>

            <activix-tooltip :content="visibilityTooltip">
                <icon :name="visibilityIcon" class="ml-2 cursor-pointer" @click="toggleVisibility" />
            </activix-tooltip>
        </div>
    </div>
</template>

<script>
    import { camelCase } from 'lodash-es';
    import colors from '../../../tailwind.colors.js';
    import UserAvailability from '../../entities/UserAvailability.js';

    export default {
        props: {
            user: {
                type: Object,
                required: true,
            },
            color: {
                type: Object,
                required: true,
            },
            visibleUsers: {
                type: Array,
                required: true,
            },
        },

        computed: {
            visible() {
                return !this.visibleUsers.length || this.visibleUsers.includes(this.user.id);
            },

            visibilityIcon() {
                if (this.visible) {
                    return 'bold/view-1';
                }

                return 'bold/view-off';
            },

            style() {
                return {
                    backgroundColor: this.visible ? this.color.background : colors.grey[200],
                    borderColor: this.visible ? this.color.border : colors.grey[400],
                    color: this.visible ? this.color.text : colors.grey[800],
                };
            },

            isOffline() {
                return this.user.availability_status != UserAvailability.SCHEDULE;
            },

            availabilityTooltip() {
                if (!this.isOffline) {
                    return '';
                }

                return this.$t(`schedule.availability.${camelCase(this.user.availability_status)}`);
            },

            disabledTooltip() {
                if (this.visible) {
                    return '';
                }

                return this.$t('schedule.visibility.onlyVisibleUsersCanBeDragged');
            },

            visibilityTooltip() {
                if (!this.visibleUsers.length) {
                    return this.$t('schedule.visibility.hideOthers');
                }

                if (this.visibleUsers.length === 1 && this.visible) {
                    return this.$t('schedule.visibility.showAll');
                }

                return this.$t(`schedule.visibility.${this.visible ? 'hide' : 'show'}`);
            },
        },

        watch: {
            visible() {
                const draggableDisabled = $(this.$el).draggable('option', 'disabled');

                if (this.visible && draggableDisabled) {
                    $(this.$el).draggable('enable');
                } else if (!this.visible && !draggableDisabled) {
                    $(this.$el).draggable('disable');
                }
            },
        },

        methods: {
            toggleVisibility() {
                this.$emit('toggle-visibility');
            },
        },

        mounted() {
            $(this.$el).draggable({
                zIndex: 1070,
                revert: true,
                revertDuration: 0,
                helper: 'clone',
                disabled: !this.visible,
            });
        },
    };
</script>
