import colors from '../../tailwind.colors.js';

export const lighter = [
    {
        background: colors.blue[300],
        border: colors.blue[400],
        text: colors.grey[800],
    },
    {
        background: colors.green[300],
        border: colors.green[400],
        text: colors.grey[800],
    },
    {
        background: colors.orange[300],
        border: colors.orange[400],
        text: colors.grey[800],
    },
    {
        background: colors.purple[300],
        border: colors.purple[400],
        text: colors.grey[800],
    },
    {
        background: colors.red[300],
        border: colors.red[400],
        text: colors.grey[800],
    },
    {
        background: colors.grey[200],
        border: colors.grey[300],
        text: colors.grey[600],
    },
    {
        background: colors.pink[300],
        border: colors.pink[400],
        text: colors.grey[800],
    },
    {
        background: colors.teal[300],
        border: colors.teal[400],
        text: colors.grey[800],
    },
    {
        background: colors.indigo[300],
        border: colors.indigo[400],
        text: colors.grey[800],
    },
];

export const light = [
    {
        background: colors.blue[400],
        border: colors.blue[500],
        text: colors.white,
    },
    {
        background: colors.green[400],
        border: colors.green[500],
        text: colors.white,
    },
    {
        background: colors.orange[400],
        border: colors.orange[500],
        text: colors.white,
    },
    {
        background: colors.purple[400],
        border: colors.purple[500],
        text: colors.white,
    },
    {
        background: colors.red[400],
        border: colors.red[500],
        text: colors.white,
    },
    {
        background: colors.grey[300],
        border: colors.grey[400],
        text: colors.grey[800],
    },
    {
        background: colors.pink[400],
        border: colors.pink[500],
        text: colors.white,
    },
    {
        background: colors.teal[400],
        border: colors.teal[500],
        text: colors.white,
    },
    {
        background: colors.indigo[400],
        border: colors.indigo[500],
        text: colors.white,
    },
];

export const normal = [
    {
        background: colors.blue[500],
        border: colors.blue[600],
        text: colors.white,
    },
    {
        background: colors.green[500],
        border: colors.green[600],
        text: colors.white,
    },
    {
        background: colors.orange[500],
        border: colors.orange[600],
        text: colors.white,
    },
    {
        background: colors.purple[500],
        border: colors.purple[600],
        text: colors.white,
    },
    {
        background: colors.red[500],
        border: colors.red[600],
        text: colors.white,
    },
    {
        background: colors.grey[400],
        border: colors.grey[500],
        text: colors.grey[800],
    },
    {
        background: colors.pink[500],
        border: colors.pink[600],
        text: colors.white,
    },
    {
        background: colors.teal[500],
        border: colors.teal[600],
        text: colors.white,
    },
    {
        background: colors.indigo[500],
        border: colors.indigo[600],
        text: colors.white,
    },
];

export const dark = [
    {
        background: colors.blue[600],
        border: colors.blue[800],
        text: colors.white,
    },
    {
        background: colors.green[600],
        border: colors.green[700],
        text: colors.white,
    },
    {
        background: colors.orange[600],
        border: colors.orange[700],
        text: colors.white,
    },
    {
        background: colors.purple[600],
        border: colors.purple[700],
        text: colors.white,
    },
    {
        background: colors.red[600],
        border: colors.red[700],
        text: colors.white,
    },
    {
        background: colors.grey[500],
        border: colors.grey[600],
        text: colors.white,
    },
    {
        background: colors.pink[600],
        border: colors.pink[700],
        text: colors.white,
    },
    {
        background: colors.teal[600],
        border: colors.teal[700],
        text: colors.white,
    },
    {
        background: colors.indigo[600],
        border: colors.indigo[700],
        text: colors.white,
    },
];

export const darker = [
    {
        background: colors.blue[800],
        border: colors.blue[900],
        text: colors.white,
    },
    {
        background: colors.green[700],
        border: colors.green[800],
        text: colors.white,
    },
    {
        background: colors.orange[700],
        border: colors.orange[800],
        text: colors.white,
    },
    {
        background: colors.purple[700],
        border: colors.purple[800],
        text: colors.white,
    },
    {
        background: colors.red[700],
        border: colors.red[800],
        text: colors.white,
    },
    {
        background: colors.grey[600],
        border: colors.grey[800],
        text: colors.white,
    },
    {
        background: colors.pink[700],
        border: colors.pink[800],
        text: colors.white,
    },
    {
        background: colors.teal[700],
        border: colors.teal[800],
        text: colors.white,
    },
    {
        background: colors.indigo[700],
        border: colors.indigo[800],
        text: colors.white,
    },
];

export const random = [
    normal[0],
    lighter[1],
    dark[2],
    light[3],
    darker[4],

    normal[5],
    lighter[6],
    dark[7],
    light[8],
    darker[0],

    normal[1],
    lighter[2],
    dark[3],
    light[4],
    darker[5],

    normal[6],
    lighter[7],
    dark[8],
    light[0],
    darker[1],

    normal[2],
    lighter[3],
    dark[4],
    light[5],
    darker[6],

    normal[7],
    lighter[8],
    dark[0],
    light[1],
    darker[2],

    normal[3],
    lighter[4],
    dark[5],
    light[6],
    darker[7],

    normal[8],
    lighter[0],
    dark[1],
    light[2],
    darker[3],

    normal[4],
    // lighter[5],
    dark[6],
    light[7],
    darker[8],
];
